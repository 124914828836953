import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesResultsServiceNowAU(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles(): any {
  return makeStyles((theme: Theme) => ({
    docType: {
      border: '1px solid #ebebeb',
      borderRadius: '2px',
      color: '#3c4043',
      display: 'inline-block',
      fontSize: '10px',
      fontWeight: 'normal',
      height: '14px',
      letterSpacing: '0.75px',
      lineHeight: '16px',
      padding: '0 3px',
      textTransform: 'uppercase',
      position: 'relative',
      top: '-1.5px'
    }
  }))
}
