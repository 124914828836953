import {
  IServiceNowAUAllResult,
  IServiceNowAUKnowledgeResult,
  IServiceNowAUServiceResult,
  ServiceNowAUOriginTypes
} from 'components/models/ServiceNowAUResult'
import { Store } from '..'

const selectors = {
  getResults: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type],
  hasResultsBeenFetched: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type].hasResultsBeenFetched,
  hasError: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type].hasError,
  getResultsCombinedQuery: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type].resultsCombinedQuery,
  getResultsCombined: (
    state: Store,
    type: ServiceNowAUOriginTypes
  ):
    | IServiceNowAUAllResult[]
    | IServiceNowAUServiceResult[]
    | IServiceNowAUKnowledgeResult[] =>
    state.resultsServiceNowAU[type].resultsCombined,
  getFeatureResults: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type].featuredResults,
  getResultCount: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type].resultCount,
  getExecutionTime: (state: Store, type: ServiceNowAUOriginTypes): any =>
    state.resultsServiceNowAU[type].executionTime,
  getHasError: (state: Store, type: ServiceNowAUOriginTypes): boolean =>
    state.resultsServiceNowAU[type].hasError,
  fetchLoadMore: (state: Store, type: ServiceNowAUOriginTypes): boolean =>
    state.resultsServiceNowAU[type].fetchLoadMore
}

export default selectors
