import React, { Dispatch, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import FilterBar from './FilterBar'
import NavBar from '../navbar/NavBar'
import StartPage from './StartPage'
import ResultsOneIntranet from './resultpages/ResultsOneIntranet'
import ResultsEngagements from './resultpages/ResultsEngagements'
import ResultsPeople from './resultpages/ResultsPeople'
import ResultsStatistics from './resultpages/ResultsStatistics'
import ResultsSource from './resultpages/ResultsSource'
import ResultsVideos from './resultpages/ResultsVideos'
import ResultsNews from './resultpages/ResultsNews'
import ResultsResearch from './resultpages/ResultsResearch'
import ResultsKnowledgeExchange from './resultpages/knowledgeexchange'
import ResultsServiceNowAU from './resultpages/servicenowau'
import ResultsMarketResearch from './resultpages/ResultsMarketResearch'
import ResultsKlardenker from './resultpages/ResultsKlardenker'
import ResultsAEM from './resultpages/ResultsAEM'
import ResultsEins from './resultpages/ResultsEins'
import ResultsProjectSpaces from './resultpages/ResultsProjectSpaces'
import ResultsContentFinder from './resultpages/ResultsContentFinder'
import ResultsMpp from './resultpages/ResultsMPP'
import ResultsAlex from './resultpages/ResultsAlex'
import Settings from './Settings'
import Faq from './Faq'
// import Roadmap from './Roadmap'
import Consent from './Consent'
import SEOChecker from './SEOChecker'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import SettingsStore from 'store/Settings'
import { trackDatasourceEvent, trackException } from 'utils/tracking'
import Office365Results from './resultpages/o365'
import { trackEvents } from 'utils/tracking'
import UserSettingsStore from 'store/UserSettings'
import { dataSourcesTabs } from 'constants/constants'
import { countriesISO } from 'constants/countriesISO'
import ImproveResults from './ImproveResults'
import { IUserSetting } from 'utils/userSettings'
import ContentFetch from './ContentFetch'
import WidgetsFetch from './WidgetsFetch'
import { useIntervalEffect } from 'utils/useIntervalEffect'
import ScrollToTop from 'components/contents/common/ScrollToTop'
import PopupContent from './PopupContent'
import {
  useFilter,
  parseFiltersFromUrlToStorage,
  getFiltersToTrack
} from 'utils/filters'
import FilterStore from 'store/Filters'
import { ISeoCheckerData } from 'utils/seoChecker'
import { CleanExpiredFromCache, MAX_TIME_CACHE } from 'utils/api'
import { getCurrentFiltersAsKeyValue } from 'utils/filtersAsKeyValue'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import ResultsIbfd from './resultpages/ResultsIbfd'
import { initializeFileTypeIcons } from '@uifabric/file-type-icons'
import ResultsImages from './resultpages/ResultsImages'
import ResultsKpmgUs from './resultpages/ResultsKpmgUs'
import ResultsChat from './resultpages/ResultsChat'
import { getCurrentScope } from 'utils/queryParams'
import FindTour from 'components/tour/FindTour'
import { enableBodyScroll } from '@fluentui/react'

interface ContentsProps {
  showRoadmap: boolean
  setShowRoadmap: Dispatch<boolean>
  showFaq: boolean
  setShowFaq: Dispatch<boolean>
  isImproveResultsModalOpen: boolean
  setImproveResultsModal: Dispatch<boolean>
  isSEOCheckerModalOpen: boolean
  seoCheckerModalData: ISeoCheckerData
  setSEOCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
  language: string
  setLanguage: Dispatch<any>
  showIntroduction: boolean
  setShowIntroduction: Dispatch<boolean>
  userName: string
  userDisplayName: string
  userSettingsFetched: boolean
  setShowNoQueryPopup: Dispatch<boolean>
  tourOpen: boolean
  setTourOpen: Dispatch<any>
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ContentsProps

function Contents(props: AllProps) {
  const {
    aadInfo,
    showFaq,
    setShowFaq,
    isImproveResultsModalOpen,
    setImproveResultsModal,
    isSEOCheckerModalOpen,
    seoCheckerModalData,
    setSEOCheckerModal,
    setSEOCheckerModalData,
    language,
    setLanguage,
    showIntroduction,
    setShowIntroduction,
    userName,
    userSettings,
    showSettings,
    setShowSettings,
    setUserSettings,
    userDisplayName,
    deviceSettings,
    fetchAPIStatus,
    userSettingsFetched,
    setShowNoQueryPopup,
    currentfilters,
    setCurrentFilter,
    clearTemporaryFilter,
    tourOpen,
    setTourOpen,
    setInternalBroadcast
  } = props

  const [showLocalFilter, setShowLocalFilter] = useState(false)
  const [cntxCountryInitialized, setCntxCountryInitialized] =
    useState<boolean>(false)
  const [tourInitPath, setTourInitPath] = useState<string | null>(null) //save path at the time the tour was opened to close it if the path changes (e.g. back button)
  const location = useLocation()
  let lastPath = useRef('')

  const queryParams =
    window.location.hash && window.location.hash !== ''
      ? queryString.parse(
          window.location.hash.split('?').length > 1
            ? window.location.hash.split('?')[1]
            : ''
        )
      : {}

  const [searchQuery] = useFilter('q')

  useEffect(() => {
    const queryParams = getCurrentFiltersAsKeyValue()
    const updateFilter: CurrentFilter[] = []

    if (queryParams.referrer) {
      trackEvents('referalLink', {
        searchTerm: queryParams.k ? queryParams.k : queryParams.q,
        referrer: queryParams.referrer,
        tab: KPMGFindGlobalVariables.getCurrentTab()
      })
      updateFilter.push({
        key: 'referrer',
        value: ''
      })
    }

    if (queryParams.cntx) {
      const cntxIndex = queryParams.cntx.lastIndexOf('/')
      if (cntxIndex >= 0) {
        const countryPart = queryParams.cntx.substring(cntxIndex + 1)
        if (countryPart && countryPart.split('_').length === 2) {
          const countryCode = countryPart.split('_')[0]
          const scope = countryPart.split('_')[1].toLowerCase()
          let validCountryCode = countryCode && countryCode.length > 0
          const countryCodeISO = countriesISO.find(
            (c) => c.Code.toLowerCase() === countryCode.toLowerCase()
          )
          if (validCountryCode && !countryCodeISO) {
            validCountryCode = false
          }

          if (
            scope === 'local' &&
            validCountryCode &&
            !queryParams.country &&
            !cntxCountryInitialized
          ) {
            updateFilter.push({
              key: 'country',
              value: countryCode
            })
            setShowLocalFilter(true)
            setCntxCountryInitialized(true)
          }
        }
      }
    }

    if (queryParams.k) {
      updateFilter.push({
        key: 'k',
        value: ''
      })
      updateFilter.push({
        key: 'q',
        value: queryParams.k
      })
    }

    if (updateFilter.length > 0) {
      setCurrentFilter(updateFilter)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentfilters])

  useEffect(() => {
    // Clean expired cached items from session storage
    const interval = setInterval(() => {
      CleanExpiredFromCache()
    }, MAX_TIME_CACHE)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // let lastPath = ''
    // in case starting the application from a url the intial pathname / is not correct
    // which leads to missing search events when switching the vertical the first time
    if (
      (window.location.pathname === '/' || window.location.pathname === '') &&
      window.location.hash &&
      window.location.hash.startsWith('#/results/')
    ) {
      let hash = window.location.hash

      hash = hash.substring(
        1,
        hash.indexOf('?') !== -1 ? hash.indexOf('?') : hash.length
      )

      lastPath.current = `${hash.replace(/\/$/, '')}/`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //prevent double tracking & tracking from startpage
    if (
      location.pathname &&
      location.pathname !== lastPath.current &&
      lastPath.current !== '/' &&
      lastPath.current !== ''
    ) {
      // Prevent tracking in case it is an redirect from a widget
      // with temporary filters applied
      if (!KPMGFindGlobalVariables.IsRedirectFromWidget()) {
        // Clear temp filters
        clearTemporaryFilter()

        const pathComponents = window.location.hash.split('?')
        const locationSearch =
          pathComponents.length > 1 ? pathComponents[1] : ''
        const queryObject = queryString.parse(locationSearch) || {}
        const searchTerm = queryObject.q || ''
        const filtersToTrack = getFiltersToTrack(
          getCurrentScope(false),
          queryObject
        )
        trackDatasourceEvent(searchTerm, filtersToTrack)
      } else {
        KPMGFindGlobalVariables.setIsRedirectFromWidget(false)
      }
    }
    lastPath.current = location.pathname

    if (tourOpen && tourInitPath && tourInitPath !== location.pathname) {
      setTourOpen(false)
      setTourInitPath(null)
    }
    if (
      userSettingsFetched &&
      userSettings.PlayTour &&
      location.pathname !== '/'
    ) {
      setTourOpen(true)
      setTourInitPath(location.pathname)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // cleanup tour
  useEffect(() => {
    //on tour close
    if (!tourOpen) {
      enableBodyScroll()

      setInternalBroadcast([
        'closeFilterBar',
        'closeDatasourceMoreMenu',
        'closeFilterMenu_orderBy',
        'closeResultMenu_text_0',
        'closeModalContactUs'
      ])

      //scroll to top
      window.scrollTo(0, 0)
    } else {
      setTourInitPath(location.pathname)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourOpen])

  useEffect(() => {
    //close settings window on first load
    setShowSettings(false)

    //redirect to page 1 on first visit for mobile
    if (
      deviceSettings.isMobile &&
      KPMGFindGlobalVariables.getCurrentTab() !== dataSourcesTabs.startpage &&
      queryParams.page !== '1'
    ) {
      queryParams.page = '1'
    }

    parseFiltersFromUrlToStorage(queryParams, currentfilters, setCurrentFilter)

    if (window && !KPMGFindGlobalVariables.getCurrentTab()) {
      KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.startpage)
    }

    // Globally register file type icons
    initializeFileTypeIcons()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      searchQuery === '' &&
      window &&
      KPMGFindGlobalVariables.getCurrentTab() &&
      KPMGFindGlobalVariables.getCurrentTab() !== dataSourcesTabs.startpage &&
      !queryParams.k
    ) {
      setShowNoQueryPopup(true)
    } else {
      setShowNoQueryPopup(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  // fetch API Status once on start
  useEffect(() => {
    fetchAPIStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // fetch API Status every 15 minutes
  useIntervalEffect(async () => {
    fetchAPIStatus()
  }, 900000)

  return (
    <>
      <>
        {!deviceSettings.renderMobile &&
          !deviceSettings.isMobile &&
          userSettingsFetched &&
          tourOpen && (
            <FindTour setTourOpen={setTourOpen} tourOpen={tourOpen} />
          )}
      </>

      <ScrollToTop searchQuery={searchQuery} />
      <ContentFetch />
      <WidgetsFetch />
      <PopupContent
        userSettings={userSettings}
        deviceSettings={deviceSettings}
      />
      <NavBar userName={userName} startPage={false} />
      <FilterBar
        showLocalFilter={showLocalFilter}
        hasSubverticals={false}
        deviceSettings={deviceSettings}
      />
      <Routes>
        <Route
          path="/"
          element={
            <StartPage
              showIntroduction={showIntroduction}
              setShowIntroduction={setShowIntroduction}
              userName={userName}
            />
          }
        />
        <Route
          path="/results/all/*"
          element={
            <ResultsOneIntranet
              setSeoCheckerModal={setSEOCheckerModal}
              setSEOCheckerModalData={setSEOCheckerModalData}
            />
          }
        />
        <Route path="/results/engagements/" element={<ResultsEngagements />} />
        <Route path="/results/people/*" element={<ResultsPeople />} />
        <Route path="/results/statistics/" element={<ResultsStatistics />} />
        <Route path="/results/source/" element={<ResultsSource />} />
        <Route path="/results/videos/" element={<ResultsVideos />} />
        <Route
          path="/results/office365/*"
          element={<Office365Results showLocalFilter={showLocalFilter} />}
        />
        <Route path="/results/news/" element={<ResultsNews />} />
        <Route path="/results/research/" element={<ResultsResearch />} />
        <Route
          path="/results/knowledgeexchange/*"
          element={
            <ResultsKnowledgeExchange showLocalFilter={showLocalFilter} />
          }
        />
        <Route
          path="/results/alex/"
          element={<ResultsAlex userName={userName} />}
        />
        <Route path="/results/klardenker/" element={<ResultsKlardenker />} />
        <Route path="/results/aem/" element={<ResultsAEM />} />
        <Route path="/results/eins/" element={<ResultsEins />} />
        <Route
          path="/results/project_spaces/"
          element={<ResultsProjectSpaces />}
        />
        <Route
          path="/results/contentfinder/"
          element={<ResultsContentFinder />}
        />
        <Route path="/results/kpmg_mpp/" element={<ResultsMpp />} />
        <Route
          path="/results/marketresearch/"
          element={<ResultsMarketResearch />}
        />
        <Route path="/results/ibfd/" element={<ResultsIbfd />} />
        <Route path="/results/images/" element={<ResultsImages />} />
        <Route path="/results/kpmgus/" element={<ResultsKpmgUs />} />
        <Route path="/results/chat/" element={<ResultsChat />} />
        <Route
          path="/results/servicenowau/*"
          element={<ResultsServiceNowAU showLocalFilter={showLocalFilter} />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {showSettings && (
        <Settings
          onClose={() => {
            setShowSettings(false)
          }}
          language={language}
          setLanguage={setLanguage}
          aadInfo={aadInfo}
          userDisplayName={userDisplayName}
        />
      )}

      {/*  <Roadmap
        open={showRoadmap}
        onClose={() => setShowRoadmap(false)}
      /> */}
      {showFaq && (
        <Faq
          open={showFaq}
          onClose={() => {
            setShowFaq(false)
          }}
        />
      )}
      <ImproveResults
        open={isImproveResultsModalOpen}
        setSeoCheckerModal={setSEOCheckerModal}
        isSEOCheckerModalOpen={isSEOCheckerModalOpen}
        setSEOCheckerModalData={setSEOCheckerModalData}
        onClose={() => {
          setImproveResultsModal(false)
        }}
      />
      {isSEOCheckerModalOpen && (
        <SEOChecker
          isImproveResultsModalOpen={isImproveResultsModalOpen}
          setImproveResultsModal={setImproveResultsModal}
          setSeoCheckerModal={setSEOCheckerModal}
          setSEOCheckerModalData={setSEOCheckerModalData}
          seoCheckerModalData={seoCheckerModalData}
          seoCheckerModalSearch={searchQuery}
          open={isSEOCheckerModalOpen}
          onClose={() => {
            setSEOCheckerModal(false)
          }}
        />
      )}
      <Consent
        open={false}
        onClose={() => {
          try {
            userSettings.TrackingAccepted = true
            setUserSettings(userSettings)
          } catch (error) {
            trackException(
              'Error in closing show consent in Contents.tsx',
              error
            )
          }
        }}
      />
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state),
    showSettings: SettingsStore.selectors.getShowSettings(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setShowSettings: (ss: boolean) =>
      dispatch(SettingsStore.actions.setShowSettings(ss)),
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings)),
    fetchAPIStatus: () => dispatch(SettingsStore.actions.fetchAPIStatus()),
    setCurrentFilter: (currentFilter: CurrentFilter[]) =>
      dispatch(FilterStore.actions.setCurrentFilter(currentFilter)),
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setInternalBroadcast: (broadcast: string[]) =>
      dispatch(SettingsStore.actions.setInternalBroadcast(broadcast))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
