import { combineReducers, Reducer } from 'redux'
import {
  IFetchSuccess,
  ResultsServiceNowAUActions,
  ServiceNowAUActionTypes
} from './actions'
import { FeaturedResult } from 'components/models/FeaturedResult'
import {
  IServiceNowAUAllResult,
  IServiceNowAUKnowledgeResult,
  IServiceNowAUServiceResult
} from 'components/models/ServiceNowAUResult'

export interface IResultsServiceNowAUStore {
  all: IResultsServiceNowAUAllStore
  knowledge: IResultsServiceNowAUKnowledgeStore
  service: IResultsServiceNowAUServiceStore
}

export interface IResultsServiceNowAUAllStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  resultsCombined: IServiceNowAUAllResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  featuredResults: FeaturedResult[]
  nextRowKBA: number
  nextRowSCI: number
  fetchLoadMore: boolean
}

export interface IResultsServiceNowAUKnowledgeStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  resultsCombined: IServiceNowAUKnowledgeResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  featuredResults: FeaturedResult[]
  nextRowKBA: number
  nextRowSCI: number
  fetchLoadMore: boolean
}

export interface IResultsServiceNowAUServiceStore {
  hasError: boolean
  hasResultsBeenFetched: boolean
  resultsCombined: IServiceNowAUServiceResult[]
  resultsCombinedQuery: string
  resultCount: number
  executionTime: number
  currentPage: number
  featuredResults: FeaturedResult[]
  nextRowKBA: number
  nextRowSCI: number
  fetchLoadMore: boolean
}

const serviceNowAUAllInitialState: IResultsServiceNowAUAllStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultsCombined: [],
  resultsCombinedQuery: '',
  resultCount: -1,
  executionTime: 0,
  currentPage: 1,
  featuredResults: [],
  nextRowKBA: 0,
  nextRowSCI: 0,
  fetchLoadMore: true
}

const serviceNowAUKnowledgeInitialState: IResultsServiceNowAUKnowledgeStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultsCombined: [],
  resultsCombinedQuery: '',
  resultCount: -1,
  executionTime: 0,
  currentPage: 1,
  featuredResults: [],
  nextRowKBA: 0,
  nextRowSCI: 0,
  fetchLoadMore: true
}

const serviceNowAUServiceInitialState: IResultsServiceNowAUServiceStore = {
  hasError: false,
  hasResultsBeenFetched: false,
  resultsCombined: [],
  resultsCombinedQuery: '',
  resultCount: -1,
  executionTime: 0,
  currentPage: 1,
  featuredResults: [],
  nextRowKBA: 0,
  nextRowSCI: 0,
  fetchLoadMore: true
}

const serviceNowAUAllReducers: Reducer<
  IResultsServiceNowAUAllStore,
  ResultsServiceNowAUActions
> = (
  state: IResultsServiceNowAUAllStore = serviceNowAUAllInitialState,
  action: ResultsServiceNowAUActions
) => {
  switch (action.type) {
    case ServiceNowAUActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      if (action.meta.type === 'all') {
        return {
          ...state,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          featuredResults: resetCombined ? [] : state.featuredResults,
          nextRowKBA: resetCombined ? 0 : state.nextRowKBA,
          nextRowSCI: resetCombined ? 0 : state.nextRowSCI
        }
      }
      return state
    case ServiceNowAUActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'all') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
      return state
    case ServiceNowAUActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'all') {
        let aggregate = true
        if (
          state.resultsCombined.length > 0 &&
          response.results.QueryResults.length > 0 &&
          state.resultsCombined[state.resultsCombined.length - 1].Link ===
            response.results.QueryResults[
              response.results.QueryResults.length - 1
            ].Link
        )
          aggregate = false
        const totalCount =
          !response.results.TotalCount || response.results.TotalCount < 20
            ? aggregate
              ? [...state.resultsCombined, ...response.results.QueryResults]
                  .length
              : state.resultsCombined.length
            : response.results.TotalCount

        return {
          ...state,
          hasResultsBeenFetched: true,
          hasError: response.results.hasError,
          resultsCombined: aggregate
            ? [...state.resultsCombined, ...response.results.QueryResults]
            : state.resultsCombined,
          featuredResults:
            response.currentPage === 1
              ? featuredResults
              : state.featuredResults,
          resultCount: totalCount,
          nextRowKBA: response.nextRowKBA,
          nextRowSCI: response.nextRowSCI,
          executionTime: response.executionTime,
          fetchLoadMore: !(
            state.nextRowKBA === response.nextRowKBA &&
            state.nextRowSCI === response.nextRowSCI - 1
          )
        }
      }
      return state
    default:
      return state
  }
}

const serviceNowAUKnowledgeReducers: Reducer<
  IResultsServiceNowAUKnowledgeStore,
  ResultsServiceNowAUActions
> = (
  state: IResultsServiceNowAUKnowledgeStore = serviceNowAUKnowledgeInitialState,
  action: ResultsServiceNowAUActions
) => {
  switch (action.type) {
    case ServiceNowAUActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      if (action.meta.type === 'knowledge') {
        return {
          ...state,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          featuredResults: resetCombined ? [] : state.featuredResults,
          nextRowKBA: resetCombined ? 0 : state.nextRowKBA,
          nextRowSCI: resetCombined ? 0 : state.nextRowSCI
        }
      }
      return state
    case ServiceNowAUActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'knowledge') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
      return state
    case ServiceNowAUActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'knowledge') {
        let aggregate = true
        if (
          state.resultsCombined.length > 0 &&
          response.results.QueryResults.length > 0 &&
          state.resultsCombined[state.resultsCombined.length - 1].Link ===
            response.results.QueryResults[
              response.results.QueryResults.length - 1
            ].Link
        )
          aggregate = false
        const totalCount =
          !response.results.TotalCount || response.results.TotalCount < 20
            ? aggregate
              ? [...state.resultsCombined, ...response.results.QueryResults]
                  .length
              : state.resultsCombined.length
            : response.results.TotalCount

        return {
          ...state,
          hasResultsBeenFetched: true,
          hasError: response.results.hasError,
          resultsCombined: aggregate
            ? [...state.resultsCombined, ...response.results.QueryResults]
            : state.resultsCombined,
          featuredResults:
            response.currentPage === 1
              ? featuredResults
              : state.featuredResults,
          resultCount: totalCount,
          nextRowKBA: response.nextRowKBA,
          nextRowSCI: response.nextRowSCI,
          executionTime: response.executionTime,
          fetchLoadMore: !(state.nextRowKBA === response.nextRowKBA)
        }
      }
      return state
    default:
      return state
  }
}

const serviceNowAUServiceReducers: Reducer<
  IResultsServiceNowAUServiceStore,
  ResultsServiceNowAUActions
> = (
  state: IResultsServiceNowAUServiceStore = serviceNowAUServiceInitialState,
  action: ResultsServiceNowAUActions
) => {
  switch (action.type) {
    case ServiceNowAUActionTypes.FETCH_REQUEST:
      const { resetCombined, searchQuery } = (action as any).payload
      if (action.meta.type === 'service') {
        return {
          ...state,
          hasResultsBeenFetched: false,
          executionTime: 0,
          resultCount: -1,
          resultsCombined: resetCombined ? [] : state.resultsCombined,
          resultsCombinedQuery: searchQuery,
          featuredResults: resetCombined ? [] : state.featuredResults,
          nextRowKBA: resetCombined ? 0 : state.nextRowKBA,
          nextRowSCI: resetCombined ? 0 : state.nextRowSCI
        }
      }
      return state
    case ServiceNowAUActionTypes.FETCH_FAILURE:
      if (action.meta.type === 'service') {
        return {
          ...state,
          hasError: true,
          hasResultsBeenFetched: true
        }
      }
      return state
    case ServiceNowAUActionTypes.FETCH_SUCCESS:
      const { response, featuredResults } = (action as IFetchSuccess).payload
      if (action.meta.type === 'service') {
        let aggregate = true
        if (
          state.resultsCombined.length > 0 &&
          response.results.QueryResults.length > 0 &&
          state.resultsCombined[state.resultsCombined.length - 1].Link ===
            response.results.QueryResults[
              response.results.QueryResults.length - 1
            ].Link
        )
          aggregate = false
        const totalCount =
          !response.results.TotalCount || response.results.TotalCount < 20
            ? aggregate
              ? [...state.resultsCombined, ...response.results.QueryResults]
                  .length
              : state.resultsCombined.length
            : response.results.TotalCount

        return {
          ...state,
          hasResultsBeenFetched: true,
          hasError: response.results.hasError,
          resultsCombined: aggregate
            ? [...state.resultsCombined, ...response.results.QueryResults]
            : state.resultsCombined,
          featuredResults:
            response.currentPage === 1
              ? featuredResults
              : state.featuredResults,
          resultCount: totalCount,
          nextRowKBA: response.nextRowKBA,
          nextRowSCI: response.nextRowSCI,
          executionTime: response.executionTime,
          fetchLoadMore: !(totalCount === response.nextRowSCI)
        }
      }
      return state
    default:
      return state
  }
}

export default combineReducers({
  all: serviceNowAUAllReducers,
  knowledge: serviceNowAUKnowledgeReducers,
  service: serviceNowAUServiceReducers
})
