import queryString from 'query-string'
import { NavigateFunction } from 'react-router-dom'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeQueryParam = (
  navigate: NavigateFunction,
  param: string
): any => {
  if (!navigate) {
    return
  }

  const locationComponents = window.location.hash.split('?')
  const queryParams = queryString.parse(
    locationComponents.length > 1 ? locationComponents[1] : ''
  )
  if (queryParams[param]) {
    delete queryParams[param]
    navigate(
      cleanUpLocationUrlForHistory(
        `${locationComponents[0]}?${queryString.stringify(queryParams)}`
      )
    )
  }
}

export const cleanUpLocationUrlForHistory = (loc: string): string => {
  if (loc && loc.indexOf('#/') === 0) {
    loc = loc.replace('#/', '/')
  }

  return loc
}

export const getCurrentScope = (navBar: boolean): string => {
  const urlSpl =
    window && window.location && window.location.hash
      ? window.location.hash.split('/')
      : []

  let scope =
    window && window.location && window.location.hash
      ? window.location.hash.indexOf('/?') >= 0
        ? window.location.hash.split('/?')[0].replace('#/results/', '')
        : window.location.hash.indexOf('?') >= 0
          ? window.location.hash.split('?')[0].replace('#/results/', '')
          : urlSpl.length > 1
            ? urlSpl[urlSpl.length - 2]
            : ''
      : window && KPMGFindGlobalVariables.getCurrentTab()
        ? KPMGFindGlobalVariables.getCurrentTab()
        : ''

  if (scope.indexOf('office365') >= 0) {
    if (!navBar) {
      if (scope === 'office365') {
        scope = 'office365files'
      } else if (scope === 'office365/mails') {
        scope = 'office365mails'
      } else if (scope === 'office365/calendar') {
        scope = 'office365calendar'
      } else if (scope === 'office365/sites') {
        scope = 'office365sites'
      } else if (scope === 'office365/teams') {
        scope = 'office365teams'
      }
    } else {
      if (scope.startsWith('office365')) {
        scope = 'office365'
      }
    }
  }

  if (scope.toLowerCase().indexOf('knowledgeexchange') >= 0) {
    if (!navBar) {
      if (scope.toLowerCase() === 'knowledgeexchange') {
        scope = 'knowledgeexchangeopen'
      } else if (scope.toLowerCase() === 'knowledgeexchange/catalog') {
        scope = 'knowledgeexchangecatalog'
      }
    } else {
      if (scope.startsWith('knowledgeexchange')) {
        scope = 'knowledgeexchange'
      }
    }
  }

  if (scope.toLowerCase().indexOf('servicenowau') >= 0) {
    if (!navBar) {
      if (scope.toLowerCase() === 'servicenowau') {
        scope = 'servicenowauall'
      } else if (scope.toLowerCase() === 'servicenowau/knowledge') {
        scope = 'servicenowauknowledge'
      } else if (scope.toLowerCase() === 'servicenowau/service') {
        scope = 'servicenowauservice'
      }
    }
  }

  switch (scope) {
    case '#':
    case '#/':
      scope = ''
      break
    case 'all':
      scope = 'intranet'
      break
    case 'aem':
      scope = 'kpmg.com'
      break
    case 'kpmg_mpp':
      scope = 'kpmg mpp'
      break
    case 'kpmgus':
      scope = 'kpmg.us'
      break
  }

  return scope
}

export const getFilterOptions = (
  scope: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  defaultFilterOptions: any,
  ptkr: string
): FilterCategory[] => {
  let filterOptions = defaultFilterOptions.intranet
  switch (scope) {
    case 'engagements':
      filterOptions = defaultFilterOptions.engagements
      break
    case 'people':
      filterOptions = defaultFilterOptions.people
      break
    case 'statistics':
      filterOptions = defaultFilterOptions.statistics
      break
    case 'source':
      filterOptions = defaultFilterOptions.source
      break
    case 'videos':
      filterOptions = defaultFilterOptions.videos
      break
    case 'news':
      filterOptions = defaultFilterOptions.news
      break
    case 'knowledgeexchangeopen':
      filterOptions = defaultFilterOptions.knowledgeexchange
      break
    case 'knowledgeexchangecatalog':
      filterOptions = defaultFilterOptions.knowledgeexchangeCatalog
      break
    case 'alex':
    case 'alexfeatured':
    case 'alextopics':
    case 'alexmanuals':
    case 'alexsearch':
      filterOptions = defaultFilterOptions.alex
      break
    case 'klardenker':
      filterOptions = defaultFilterOptions.klardenker
      break
    case 'kpmg.com':
      filterOptions = defaultFilterOptions.kpmgwebsites
      break
    case 'eins':
      filterOptions = defaultFilterOptions.eins
      break
    case 'project_spaces':
      filterOptions = defaultFilterOptions.project_spaces
      break
    case 'contentfinder':
      filterOptions = defaultFilterOptions.contentfinder
      break
    case 'office365files':
      filterOptions = defaultFilterOptions.office365files
      break
    case 'office365mails':
      filterOptions = defaultFilterOptions.office365mails
      break
    case 'office365calendar':
      filterOptions = defaultFilterOptions.office365calendar
      break
    case 'office365sites':
      filterOptions = defaultFilterOptions.office365sites
      break
    case 'office365teams':
      filterOptions = defaultFilterOptions.office365teams
      break
    case 'kpmg mpp':
      filterOptions = defaultFilterOptions.kpmg_mpp
      break
    case 'images':
      filterOptions = defaultFilterOptions.images
      break
    case 'kpmg.us':
      filterOptions = defaultFilterOptions.kpmgus
      break
    case 'marketresearch':
      filterOptions = defaultFilterOptions.marketresearch
      if (scope === 'marketresearch' && ptkr !== '') {
        filterOptions = [
          ...filterOptions,
          {
            key: 'ptkr',
            name: 'Company',
            options: [
              {
                name: ptkr,
                key: ptkr,
                value: ptkr,
                image: '',
                isDefaultFilter: true
              }
            ],
            singleOption: true,
            value: 'ptkr'
          }
        ]
      }
      break
    case 'ibfd':
      filterOptions = defaultFilterOptions.ibfd
      break
    case 'chat':
      filterOptions = defaultFilterOptions.chat
      break
    case 'servicenowauall':
      filterOptions = defaultFilterOptions.servicenowauall
      break
    case 'servicenowauknowledge':
      filterOptions = defaultFilterOptions.servicenowauknowledge
      break
    case 'servicenowauservice':
      filterOptions = defaultFilterOptions.servicenowauservice
      break
  }
  return filterOptions
}
