import React from 'react'
import * as Config from 'config'
import { ResultRightWidgetGenerator } from './interfaces'
import { trackException } from 'utils/tracking'
import { FetchWithCache, FetchWithCacheAndTracking } from 'utils/api'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { stripHtml } from 'utils/string'
import RightServiceNowAUWidget from 'components/contents/resultpages/widgets/RightServiceNowAUWidget'
import { IServiceNowAUAllResult } from 'components/models/ServiceNowAUResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { renewAuthorizationToken } from 'utils/token'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { IAADState } from 'store/Auth/reducers'

export const rightServiceNowAUWidgetGenerator: ResultRightWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResult: IServiceNowAUAllResult[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResult: IServiceNowAUAllResult[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchServiceNowAUArticles = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Service Now right widget in rightServiceNowAUWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        let _searchQuery = searchQuery
        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }

          _searchQuery = removeStopWordsFromQuery(
            searchQuery,
            searchQueryLanguage,
            useCognitiveSearch,
            findConfiguration.CognitiveSearchEnabled
          )
        }

        let apiUrl = `${
          Config.APIM_BASE_URL
        }/servicenowauapi/getsearch?query=${unifysearchQuery(
          _searchQuery,
          'servicenowau'
        )}&limit=10&offset=0`

        if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
          apiUrl += '&cognitiveEnabled=true'
        }

        const response = await FetchWithCacheAndTracking(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        let serviceNowAUResponse
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Service Now right widget in rightServiceNowAUWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )

          reject()
          return
        } else {
          serviceNowAUResponse = response.responseJSON
        }

        if (
          !serviceNowAUResponse ||
          !serviceNowAUResponse.Results ||
          serviceNowAUResponse.Results.length < 3
        ) {
          reject()
          return
        }

        const serviceNowAUResults = serviceNowAUResponse.Results.map(
          (i: any) => {
            const serviceNowAUArticle: IServiceNowAUAllResult = {
              Id: i.Id,
              Title: stripHtml(i.Title),
              Description: stripHtml(i.Description),
              Type: i.Type,
              Link: i.Link
            }

            return serviceNowAUArticle
          }
        )

        resolve({
          queryResult: serviceNowAUResults,
          synonymsApplied: serviceNowAUResponse.SynonymsApplied
        })
      }

      try {
        fetchServiceNowAUArticles()
      } catch (error) {
        trackException(
          'Error in fetching Service Now right widget in rightServiceNowAUWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResult: IServiceNowAUAllResult[]
      synonymsApplied: ISynonymsApplied[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateServiceNowAU = () => {
        let articles: IServiceNowAUAllResult[] = resultsRaw.queryResult

        if (articles.length < 3) {
          reject()
        }

        if (articles.length > 10) {
          articles = articles.slice(0, 10)
        }

        resolve(
          <RightServiceNowAUWidget
            searchQuery={searchQuery}
            results={articles}
            synonymsApplied={resultsRaw.synonymsApplied}
          />
        )
      }

      try {
        generateServiceNowAU()
      } catch (error) {
        trackException(
          'Error in generating Service Now right widget in rightServiceNowAUWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
