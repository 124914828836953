export const searchDebounceTime = 500

export const desktopViewWidth = 1024

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
}

export const googleMapsSearchUrl = 'https://www.google.com/maps/search'

export const dataSourcesTabs = {
  startpage: 'startpage',
  aem: 'kpmg.com',
  alex: 'alex',
  aussharepoint: 'aussharepoint',
  engagements: 'engagements',
  intranet: 'intranet',
  klardenker: 'klardenker',
  knowledgeexchangeopen: 'knowledgeexchange_open',
  knowledgeexchangecatalog: 'knowledgeexchange_catalog',
  news: 'news',
  o365files: 'o365_files',
  o365mails: 'o365_mails',
  o365calendar: 'o365_calendar',
  o365sites: 'o365_sites',
  o365teams: 'o365_teams',
  oneintranet: 'intranet',
  people: 'people',
  research: 'research',
  source: 'source',
  statistics: 'statistics',
  marketresearch: 'marketresearch',
  videos: 'videos',
  eins: 'eins',
  project_spaces: 'project_spaces',
  contentfinder: 'contentfinder',
  kpmg_mpp: 'mpp',
  ibfd: 'ibfd',
  images: 'images',
  kpmgus: 'kpmg.us',
  chat: 'chat',
  servicenowauall: 'servernowau_all',
  servicenowauknowledge: 'servernowau_knowledge',
  servicenowauservice: 'servernowau_service'
}

export const sharePoint = {
  localEmaBaseUrl:
    'https://intra.ema.kpmg.com/sites/SearchCenter/pages/This_Site.aspx',
  localAmrBaseUrl:
    'https://intra.amr.kpmg.com/sites/SearchCenter/pages/This_Site.aspx',
  localAspacBaseUrl:
    'https://intra.aspac.kpmg.com/sites/SearchCenter/pages/This_Site.aspx',
  localStgBaseUrl:
    'https://intra.stg.kpmg.com/sites/SearchCenter/pages/This_Site.aspx',
  globalEmaBaseUrl:
    'https://intra.ema.kpmg.com/sites/SearchCenter/Pages/All_Portals.aspx',
  globalAmrBaseUrl:
    'https://intra.amr.kpmg.com/sites/SearchCenter/Pages/All_Portals.aspx',
  globalAspacBaseUrl:
    'https://intra.aspac.kpmg.com/sites/SearchCenter/Pages/All_Portals.aspx',
  globalStgBaseUrl:
    'https://intra.stg.kpmg.com/sites/SearchCenter/Pages/All_Portals.aspx',
  peopleBaseUrl:
    'https://intra.ema.kpmg.com/sites/SearchCenter/Pages/peopleresults.aspx',
  localContextParam: 'u',
  globalContextParam: 'cntx',
  peopleContextParam: 'cntx',
  localQueryParam: 'k',
  globalQueryParam: 'k',
  peopleQueryParam: 'k'
}

export const translationTypes = {
  ads: 'Translations-Ads',
  faq: 'Translations-Faq',
  global: 'Translations-Global',
  globaloi: 'Translations-Global-OI',
  introduction: 'Translations-Introduction',
  roadmap: 'Translations-Roadmap',
  popups: 'Translations-Popups'
}

export const alexResultTypes = {
  featured: 'alexfeatured',
  topics: 'alextopics',
  manuals: 'alexmanuals',
  search: 'alexsearch'
}

export const defaultValues = {
  noCountry: 'Unknown Country'
}

export const lexisNexisLanguages = {
  english: 'English',
  french: 'French',
  german: 'German',
  spanish: 'Spanish',
  dutch: 'Dutch',
  portuguese: 'Portuguese',
  italian: 'Italian',
  russian: 'Russian',
  japanese: 'Japanese',
  danish: 'Danish',
  swedish: 'Swedish',
  norwegian: 'Norwegian',
  indonesian: 'Indonesian',
  vietnamese: 'Vietnamese',
  romanian: 'Romanian',
  turkish: 'Turkish',
  korean: 'Korean',
  greek: 'Greek',
  arabic: 'Arabic',
  afrikaans: 'Afrikaans',
  croatian: 'Croatian',
  czech: 'Czech',
  catalan: 'Catalan',
  slovak: 'Slovak',
  lithuanian: 'Lithuanian',
  estonian: 'Estonian',
  hungarian: 'Hungarian',
  icelandic: 'Icelandic',
  bulgarian: 'Bulgarian',
  polish: 'Polish',
  chinese: 'Chinese',
  finnish: 'Finnish',
  malay: 'Malay'
}

export const statistaPremiumCountries = [
  'Germany',
  'Canada',
  'United States',
  'Mexico',
  'USA',
  'Brazil',
  'Ireland',
  'Luxembourg',
  'Luxemburg',
  'Spain',
  "Côte d'Ivoire",
  'Ivory Coast',
  'Turkey',
  'India',
  'Vietnam',
  'Japan'
]

export const imageOrVideoFileExtensions = [
  'mp4',
  'mp3',
  'bmp',
  'jpg',
  'jpeg',
  'png',
  'tiff',
  'gif',
  'rle',
  'wmf',
  'dib',
  'ico',
  'wpd',
  'odg'
]

export const invalidManualFiltersMPP = [
  'test',
  'TestManual',
  'TSS',
  'TSD',
  'UC',
  'mppeditor'
]

export const emptyGuid = '00000000-0000-0000-0000-000000000000'

export const enterpriseSearchEmail = 'go-fmenterprisesearc@kpmg.com'

export const chatSearchTypes = {
  keyword: 'Keyword Search',
  hybrid: 'Hybrid Search'
}
