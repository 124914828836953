import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import {
  IServiceNowAUAllResult,
  IServiceNowAUKnowledgeResult,
  IServiceNowAUServiceResult,
  ServiceNowAUOriginTypes
} from 'components/models/ServiceNowAUResult'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import ResultMetaDataStore from 'store/ResultMetaData'
import AuthStore from '../Auth'
import { renewAuthorizationToken } from 'utils/token'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import { trackEvents } from 'utils/tracking'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import Selectors from './selectors'

export enum ServiceNowAUActionTypes {
  FETCH_REQUEST = 'resultsServiceNowAU/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsServiceNowAU/FETCH_SUCCESS',
  FETCH_FAILURE = 'resultsServiceNowAU/FETCH_FAILURE'
}

export interface IFetchRequest extends CustomAction<ServiceNowAUActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
  meta: {
    type: ServiceNowAUOriginTypes
  }
}

export interface IFetchFailure extends CustomAction<ServiceNowAUActionTypes> {
  meta: {
    type: ServiceNowAUOriginTypes
  }
}

export interface IFetchSuccess extends CustomAction<ServiceNowAUActionTypes> {
  payload: {
    response: any
    featuredResults: FeaturedResult[]
  }
  meta: {
    type: ServiceNowAUOriginTypes
  }
}

export const fetchRequest = (
  type: ServiceNowAUOriginTypes,
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ServiceNowAUActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  meta: { type },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})

export const fetchSuccess = (
  response: any = {},
  type: ServiceNowAUOriginTypes,
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ServiceNowAUActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    featuredResults
  },
  meta: { type },
  metaData: actionMetaData
})

export const fetchFailure = (
  type: ServiceNowAUOriginTypes,
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ServiceNowAUActionTypes.FETCH_FAILURE,
  meta: { type },
  metaData: actionMetaData
})

export const fetchResultsServiceNowAU = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  findConfiguration: IFindConfiguration,
  filters: {
    [key: string]: string
  },
  type: ServiceNowAUOriginTypes = 'all',
  tracking = false
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('servicenowau', type)

    dispatch(
      ResultMetaDataStore.actions.fetchRequest(
        'servicenowau' + type,
        actionMetaData
      )
    )

    const state = getState()

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(
      getState(),
      type
    )

    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(type, false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(type, true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(type, false, searchQuery, actionMetaData))
    }

    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(type, actionMetaData))
        return
      }

      let nextRowKBA = 0
      let nextRowSCI = 0
      if (type === 'all' || type === 'knowledge') {
        nextRowKBA =
          state && (currentPage || 1) !== 1
            ? state.resultsServiceNowAU[type].nextRowKBA
            : 0
      }
      if (type === 'all' || type === 'service') {
        nextRowSCI =
          state && (currentPage || 1) !== 1
            ? state.resultsServiceNowAU[type].nextRowSCI
            : 0
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const t0 = performance.now()
      let apiUrl = ''
      let maxItemsSCI = -1
      if (
        state.resultsServiceNowAU &&
        state.resultsServiceNowAU.service &&
        state.resultsServiceNowAU.service.resultCount
      ) {
        maxItemsSCI = state.resultsServiceNowAU.service.resultCount - 1
      }

      if (type === 'all') {
        apiUrl = `${
          Config.APIM_BASE_URL
        }servicenowauapi/getfederatedsearch?query=${encodeURIComponent(searchQuery)}&limit=20&offsetkba=${nextRowKBA}&offsetsci=${nextRowSCI}&maxitemssci=${maxItemsSCI}&processfeaturedresults=${
          nextRowKBA === 0 && nextRowSCI === 0 ? true : false
        }&origin=OI&country=${userSettings.Country}&city=${
          userSettings.City
        }&function=${userSettings.Function}`
      } else if (type === 'knowledge') {
        apiUrl = `${
          Config.APIM_BASE_URL
        }servicenowauapi/getknowledgebasearticles?query=${encodeURIComponent(searchQuery)}&limit=20&offset=${nextRowKBA}&processfeaturedresults=${
          nextRowKBA === 0 ? true : false
        }&origin=OI&country=${userSettings.Country}&city=${
          userSettings.City
        }&function=${userSettings.Function}&federated=false`
      } else if (type === 'service') {
        if (maxItemsSCI === nextRowSCI) {
          return
        }
        apiUrl = `${
          Config.APIM_BASE_URL
        }servicenowauapi/getservicecatalogitems?query=${encodeURIComponent(searchQuery)}&limit=20&offset=${nextRowSCI}&processfeaturedresults=${
          nextRowSCI === 0 ? true : false
        }&origin=OI&country=${userSettings.Country}&city=${
          userSettings.City
        }&function=${userSettings.Function}&federated=false`
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'servicenowau' + type,
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(type, actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      let resultCount = 0
      const serviceNowAUAllResults: IServiceNowAUAllResult[] = []
      const serviceNowAUKnowledgeResults: IServiceNowAUKnowledgeResult[] = []
      const serviceNowAUServiceResults: IServiceNowAUServiceResult[] = []

      if (results && results.Results) {
        results.Results.forEach((item: any) => {
          if (type === 'all') {
            serviceNowAUAllResults.push({
              Id: item.Id ? item.Id : '',
              Title: item.Title ? item.Title : '',
              Description: item.Description ? item.Description : '',
              Link: item.Link ? item.Link : '',
              Type: item.Type ? item.Type : ''
            })
          } else if (type === 'knowledge') {
            serviceNowAUKnowledgeResults.push({
              Id: item.Id ? item.Id : '',
              Title: item.Title ? item.Title : '',
              Description: item.Description ? item.Description : '',
              Link: item.Link ? item.Link : '',
              Type: item.Type ? item.Type : ''
            })
          } else if (type === 'service') {
            serviceNowAUServiceResults.push({
              Id: item.Id ? item.Id : '',
              Title: item.Title ? item.Title : '',
              Description: item.Description ? item.Description : '',
              Link: item.Link ? item.Link : '',
              Type: item.Type ? item.Type : ''
            })
          }
        })
      }

      if (
        (type === 'all' || type === 'knowledge') &&
        results &&
        results.NextKBA
      ) {
        nextRowKBA = parseInt(results.NextKBA)
        resultCount += nextRowKBA
      }

      if (
        (type === 'all' || type === 'service') &&
        results &&
        results.NextSCI
      ) {
        nextRowSCI = parseInt(results.NextSCI)
        resultCount += nextRowSCI
      }

      if (results.TotalCount) {
        resultCount = parseInt(results.TotalCount)
      }

      const t1 = performance.now()
      const executionTime = t1 - t0
      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []
      const result = {
        QueryResults:
          type === 'all'
            ? serviceNowAUAllResults
            : type === 'knowledge'
              ? serviceNowAUKnowledgeResults
              : serviceNowAUServiceResults,
        TotalCount: resultCount,
        hasError: false,
        executionTime: executionTime
      }

      if (result) {
        if (resultCount === 0 && tracking) {
          trackEvents('zero_result_queries', {
            searchTerm: searchQuery,
            tab: KPMGFindGlobalVariables.getCurrentTab(),
            enabledFilters: KPMGFindGlobalVariables.getEnabledFilters(),
            distinct: resultsCombinedQuery !== searchQuery
          })
        }

        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'servicenowau' + type,
            {
              executionTime: executionTime,
              resultsCount: resultCount
            },
            actionMetaData
          )
        )

        dispatch(
          fetchSuccess(
            {
              executionTime,
              resultCount: resultCount,
              results: result,
              nextRowKBA: nextRowKBA,
              nextRowSCI: nextRowSCI,
              SynonymsApplied: []
            },
            type,
            featuredResults,
            actionMetaData
          )
        )
      }
      return
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'servicenowau' + type,
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(type, actionMetaData))
    }
  }
}

export type ResultsServiceNowAUActions = IFetchRequest | IFetchFailure
