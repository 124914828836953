import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import FilterBar from '../../FilterBar'
import PageStay from 'components/HOC/PageStay'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import ResultKnowledge from './ResultKnowledge'
import ResultService from './ResultService'
import ResultAll from './ResultAll'

interface ResultServiceNowAUProps {
  showLocalFilter: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultServiceNowAUProps

function ResultServiceNowAU(props: AllProps) {
  const { showLocalFilter, deviceSettings } = props

  const [internalDataSources] = useState([
    {
      name: 'All',
      enabled: true,
      path: '/results/servicenowau'
    },
    {
      name: 'Knowledge',
      enabled: true,
      path: '/results/servicenowau/knowledge'
    },
    {
      name: 'Service',
      enabled: true,
      path: '/results/servicenowau/service'
    }
  ])

  return (
    <>
      <Routes>
        <Route
          path={'/knowledge'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultKnowledge {...props} datasources={internalDataSources} />
            </>
          }
        />
        <Route
          path={'/service'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultService {...props} datasources={internalDataSources} />
            </>
          }
        />
        <Route
          path={'/'}
          element={
            <>
              <FilterBar
                hasSubverticals={true}
                showLocalFilter={showLocalFilter}
                deviceSettings={deviceSettings}
              />
              <ResultAll {...props} datasources={internalDataSources} />
            </>
          }
        />
      </Routes>
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps, null)(ResultServiceNowAU))
