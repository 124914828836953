import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Fade, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultMetaDataStore from 'store/ResultMetaData'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsServiceNowAUStore from 'store/ResultsServiceNowAU'
import PageStay from 'components/HOC/PageStay'
import Pagination from '../../Pagination'
import TextResult from 'components/contents/results/TextResult'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import { trackException } from 'utils/tracking'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import FeaturedResults from '../FeaturedResults'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import RightWidgetContainer from '../widgetscontainer/RightWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { useLocation } from 'react-router-dom'
import ErrorPage from 'components/contents/common/ErrorPage'
import ServiceNowAUFilterBar from './ServiceNowAUFilterBar'
import { getStylesResultsServiceNowAU } from 'styles/contents/resultpages/ServiceNowAU'

export interface ResultServiceProps {
  searchTerm: string
  datasources: any
}

type AllProps = ReturnType<typeof mapStateToProps> & ResultServiceProps

function ResultService(props: AllProps) {
  const {
    featuredResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    deviceSettings,
    datasources,
    fetchLoadMore
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const classes = getStylesResultsServiceNowAU()
  const { getQueryString } = useReactRouterQueryStringInterface()
  const intl = useIntl()
  const queryStringFromUrl = getQueryString()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.servicenowauservice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setup = useCallback(() => {
    try {
      authorizeResultPage(
        'ServiceNowAU',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )
    } catch (error) {
      trackException('Error in fetching results in ResultService.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  useEffect(() => {
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringFromUrl])

  const returnAllItems = (type: string) => {
    // if no results are present, make sure to not render the empty result frames
    if (!results || results.length < 1) {
      return []
    }

    const items = []
    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <TextResult
              title={results[i].Title}
              body={DOMPurify.sanitize(results[i].Description)}
              url={DOMPurify.sanitize(results[i].Link)}
              featured={false}
              showContextMenu={false}
              disableSharing={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              openInWeb={false}
              displayUrl={
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={classes.docType}
                >
                  {DOMPurify.sanitize(results[i].Type)}
                </Typography>
              }
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          items.push(
            <TextResult
              title={results[i].Title}
              body={DOMPurify.sanitize(results[i].Description)}
              url={DOMPurify.sanitize(results[i].Link)}
              featured={false}
              showContextMenu={false}
              disableSharing={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              openInWeb={false}
              displayUrl={
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={classes.docType}
                >
                  {DOMPurify.sanitize(results[i].Type)}
                </Typography>
              }
            />
          )
        }
      }
    } catch (error) {
      trackException('Error in returning all items in ResultService.tsx', error)
    }
    return items
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstItems = useMemo(() => returnAllItems('first'), [results, intl])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restItems = useMemo(() => returnAllItems('rest'), [results, intl])

  return (
    <Box className={resultClasses.container}>
      <Box
        id={'con-resultvertical-01'}
        className={`${resultClasses.resultsContainer} ${resultClasses.subResultsContainer}`}
      >
        {!hasErrorStore && (
          <SearchSuggestions
            wildCardActive={true}
            resultCount={resultsCount}
            resultsFetchend={hasResultsBeenFetched}
          />
        )}
        <ServiceNowAUFilterBar
          {...props}
          scope={'Service'}
          datasources={datasources}
        />
        {!hasErrorStore && (
          <Box>
            <ReactPlaceholder
              ready={hasResultsBeenFetched || Number(currentPage) > 1}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched || Number(currentPage) > 1}
                timeout={600}
              >
                <Box id={'con-resultvertical-section-01'}>
                  {results && resultsCount > 0 && (
                    <FeaturedResults featuredResults={featuredResults} />
                  )}
                  {firstItems}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <LeftWidgetContainer
              resultCount={resultsCount}
              hasResultsBeenFetched={hasResultsBeenFetched}
            />
            <ReactPlaceholder
              ready={hasResultsBeenFetched || Number(currentPage) > 1}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Fade
                in={hasResultsBeenFetched || Number(currentPage) > 1}
                timeout={600}
              >
                <Box id={'con-resultvertical-section-02'}>
                  {restItems}
                  {resultsCount != null &&
                    resultsCount > 0 &&
                    fetchLoadMore && (
                      <Pagination
                        {...props}
                        totalRecords={resultsCount}
                        pageLimit={20}
                        pageNeighbours={4}
                        loadMore={true}
                      />
                    )}
                </Box>
              </Fade>
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
            <ReactPlaceholder
              ready={hasResultsBeenFetched}
              type="text"
              rows={4}
              showLoadingAnimation={true}
              className={resultClasses.placeholder}
            >
              <Box />
            </ReactPlaceholder>
          </Box>
        )}
        {hasErrorStore && (
          <ErrorPage
            dataSource="servicenowau"
            origin={'service'}
            isContainerWrapped={true}
          />
        )}
      </Box>
      <Box className={resultClasses.advertisementContainer}>
        <RightTopWidgetContainer
          searchQuery={searchQuery}
          scope={'servicenowauservice'}
        />
        <RightWidgetContainer />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsServiceNowAUStore.selectors.getFeatureResults(
      state,
      'service'
    ),
    results: ResultsServiceNowAUStore.selectors.getResultsCombined(
      state,
      'service'
    ),
    resultsCount: ResultsServiceNowAUStore.selectors.getResultCount(
      state,
      'service'
    ),
    executionTime: ResultsServiceNowAUStore.selectors.getExecutionTime(
      state,
      'service'
    ),
    hasResultsBeenFetched:
      ResultsServiceNowAUStore.selectors.hasResultsBeenFetched(
        state,
        'service'
      ),
    fetchLoadMore: ResultsServiceNowAUStore.selectors.fetchLoadMore(
      state,
      'service'
    ),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'servicenowauservice'
    ),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default PageStay(connect(mapStateToProps, null)(ResultService))
