import React, { Dispatch, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { Button, CardMedia, Grid, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import NavBar from '../navbar/NavBar'
import SearchField from './SearchField'
import Introduction from './Introduction'
import FeedbackShort from './FeedbackShort'
import FeedbackLong from './FeedbackLong'
import { Store } from 'store'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import { trackEvents } from 'utils/tracking'
import PageStay from '../HOC/PageStay'
import Logo from 'images/find_logo.png'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { getCountryKeyFromName, useFilter } from 'utils/filters'
import { IUserSetting } from 'utils/userSettings'
import { dataSourcesTabs } from 'constants/constants'
import { getStylesStartPage } from 'styles/contents/StartPage'
import dayjs from 'dayjs'
import SearchStore from 'store/Search'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { removeQueryParam } from 'utils/queryParams'
import {
  updateHistory,
  updateHistoryForAlex,
  updateHistoryForKnowledgeExchange,
  updateHistoryForOffice365,
  updateHistoryForServiceNowAU
} from 'utils/searchField'

export interface StartPageProps {
  onMenuButtonClick: any
  userName: string
  location?: any
  showIntroduction: boolean
  setShowIntroduction: Dispatch<boolean>
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  StartPageProps

function StartPage(props: AllProps) {
  const {
    onMenuButtonClick,
    userName,
    userSettings,
    setUserSettings,
    showIntroduction,
    setShowIntroduction,
    currentDataSources,
    findConfiguration,
    hasFindConfigurationBeenFetched,
    storeSearchHistory,
    deviceSettings
  } = props
  const [showFeedbackShort, setShowFeedbackShort] = useState(false)
  const [showFeedbackLong, setShowFeedbackLong] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isChina, setIsChina] = useState(false)
  const classes = getStylesStartPage()
  const intl = useIntl()
  const navigateFunction = useNavigate()
  const [cpfValue, setCpfValue] = useFilter('country', '')
  const [premium, setPremium] = useFilter('premium', '')
  const [, setCurrentPage] = useFilter('page', '1')
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [, setSearchTermFilter] = useFilter('q')
  const { getQueryString } = useReactRouterQueryStringInterface()

  const enablePrefilteringFromLocalStorage = userSettings.EnablePrefiltering
  const isInitialMount = React.useRef(true)

  useEffect(() => {
    if (!userSettings) {
      return
    }
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (!Boolean(enablePrefilteringFromLocalStorage)) {
        setCpfValue('')
      } else if (enablePrefilteringFromLocalStorage) {
        if (userSettings.Country) {
          const countryKey = getCountryKeyFromName(userSettings.Country)
          if (countryKey) {
            setCpfValue(countryKey)
          } else {
            setCpfValue('')
          }
        } else {
          setCpfValue('')
        }
      } else {
        setCpfValue('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enablePrefilteringFromLocalStorage, userSettings.Country])

  useEffect(() => {
    if (!userSettings) {
      return
    }
    if (enablePrefilteringFromLocalStorage && cpfValue === '') {
      if (userSettings.Country) {
        const countryKey = getCountryKeyFromName(userSettings.Country)
        if (countryKey) {
          setCpfValue(countryKey)
        } else {
          setCpfValue('')
        }
      } else {
        setCpfValue('')
      }
    }

    if (userSettings.Country) {
      setIsChina(userSettings.Country.toLowerCase() === 'china')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Country])

  useEffect(() => {
    const setup = async () => {
      // Init premium if needed
      if (!premium) setPremium(userSettings.PremiumEnabled ? '1' : '0')

      let firstLogin = userSettings.FirstLogin || 0

      const feedbackShortShowed = userSettings.FeedbackShortShowed
      const feedbackLongShowed = userSettings.FeedbackLongShowed
      let userSettingsMustBeUpdate = false

      if (firstLogin === 0) {
        firstLogin = Date.now()
        userSettings.FirstLogin = firstLogin
        userSettingsMustBeUpdate = true
      }

      if (
        findConfiguration.FeedbackRequiredVisits &&
        findConfiguration.FeedbackLongWeeksAfterShort
      ) {
        let visitsXTimesPastTwoWeeks =
          userSettings.LastVisits.length ===
          findConfiguration.FeedbackRequiredVisits
        userSettings.LastVisits.forEach((lastVisit: number) => {
          if (dayjs().diff(new Date(lastVisit), 'week', true) > 2) {
            visitsXTimesPastTwoWeeks = false
          }
        })

        //is short feedback delayed
        let isShortFeedbackDelayed = false,
          isLongFeedbackDelayed = false
        if (userSettings.FeedbackShortDelayedOn > 0) {
          const weeksSinceDelay = dayjs().diff(
            new Date(userSettings.FeedbackShortDelayedOn),
            'week',
            true
          )
          isShortFeedbackDelayed = weeksSinceDelay < 1
        }

        //is long feedback delayed
        if (userSettings.FeedbackLongDelayedOn > 0) {
          const weeksSinceDelay = dayjs().diff(
            new Date(userSettings.FeedbackLongDelayedOn),
            'week',
            true
          )
          isLongFeedbackDelayed = weeksSinceDelay < 1
        }

        // Get the date difference between last login and current date in weeks
        const weeks = dayjs().diff(new Date(firstLogin), 'week', true)

        // Show feedbach short if no shown before, no introduction and version update needs to be shown
        // and last login is greater than 2 weeks
        if (
          visitsXTimesPastTwoWeeks &&
          weeks >= 2 &&
          !feedbackShortShowed &&
          userSettings.IntroductionShown &&
          !isShortFeedbackDelayed
        ) {
          setShowFeedbackShort(true)
        }

        if (
          userSettings.FeedbackShortShownTime &&
          userSettings.FeedbackShortShownTime > 0
        ) {
          // Get the date difference between short feedback shown and current date in months
          const weekAfterFeedbackshort = dayjs().diff(
            new Date(userSettings.FeedbackShortShownTime),
            'week',
            true
          )

          // Show feedback long based on criteria defined in US
          // https://go-platforms.visualstudio.com/KPMG%20Find/_workitems/edit/535342
          if (
            userSettings.FeedbackShortShowed &&
            visitsXTimesPastTwoWeeks &&
            weekAfterFeedbackshort >=
              findConfiguration.FeedbackLongWeeksAfterShort &&
            !feedbackLongShowed &&
            userSettings.IntroductionShown &&
            !isLongFeedbackDelayed
          ) {
            setShowFeedbackLong(true)
          }
        }
      }

      if (userSettingsMustBeUpdate) {
        setUserSettings(userSettings)
      }
    }

    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.startpage)

    if (hasFindConfigurationBeenFetched) {
      setup()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFindConfigurationBeenFetched])

  const onFeedbackDone = (longFeedback: boolean) => {
    if (longFeedback) {
      userSettings.FeedbackLongShowed = true
      userSettings.FeedbackLongDelayed = 0
      userSettings.FeedbackLongDelayedOn = 0
    } else {
      userSettings.FeedbackShortShowed = true
      userSettings.FeedbackShortShownTime = new Date().getTime()
      userSettings.FeedbackShortDelayed = 0
      userSettings.FeedbackShortDelayedOn = 0
    }
    setUserSettings(userSettings)
  }
  const onFeedbackDelay = (longFeedback: boolean) => {
    if (longFeedback) {
      userSettings.FeedbackLongShowed = false
      userSettings.FeedbackLongDelayed = userSettings.FeedbackLongDelayed + 1 //count number of delays
      userSettings.FeedbackLongDelayedOn = dayjs().valueOf()
    } else {
      userSettings.FeedbackShortShowed = false
      userSettings.FeedbackShortShownTime = 0
      userSettings.FeedbackShortDelayed = userSettings.FeedbackShortDelayed + 1 //count number of delays
      userSettings.FeedbackShortDelayedOn = dayjs().valueOf()
    }
    setUserSettings(userSettings)
  }

  const redirectionUrl: any[] = []
  let destinationPage = ''

  currentDataSources.slice(0, 1).map((ds: any) => {
    redirectionUrl.push(ds.path)
    return null
  })

  destinationPage = redirectionUrl.toString()

  if (!destinationPage) {
    destinationPage = 'all'
  }

  const performSearch = (value: any, isAutoSuggest = false) => {
    if (!value) {
      return
    }
    // this will be done every time a search is performed
    // it will also add the searchquery to the top 10 search results
    // in the cosmos db
    storeSearchHistory(value, userSettings)
    removeQueryParam(navigateFunction, 'cntx')

    // set the autosuggest value
    // this will be used in the content fetch to
    // set the property for tracking the search
    KPMGFindGlobalVariables.setIsAutoSuggestQuery(isAutoSuggest)

    // update the query string for the query and reset the page to 1
    const qs = queryString.parse(getQueryString()) || {}
    qs.page = '1'
    qs.q = value
    setCurrentPage('1')
    setSearchTermFilter(value)

    let pathComponents = destinationPage.replace('/results/', '')
    if (pathComponents.endsWith('/')) {
      pathComponents = pathComponents.slice(0, -1)
    }

    if (destinationPage.indexOf('office365') >= 0) {
      // For office 365 an push in the history for mails and calender must
      // be explicitly set. Scope is office365 and it is not possible
      // to make this difference in the scope itself.
      updateHistoryForOffice365(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else if (destinationPage.indexOf('knowledgeexchange') >= 0) {
      updateHistoryForKnowledgeExchange(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else if (destinationPage.indexOf('alex') >= 0) {
      updateHistoryForAlex(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else if (destinationPage.indexOf('servicenowau') >= 0) {
      updateHistoryForServiceNowAU(
        navigateFunction,
        queryString.stringify(qs),
        pathComponents
      )
    } else {
      updateHistory(
        navigateFunction,
        queryString.stringify(qs),
        currentDataSources,
        pathComponents
      )
    }
  }

  const SearchButtons = (props: any) => (
    <Grid
      container
      item
      justifyContent="center"
      spacing={2}
      className={classes.buttonRow}
    >
      <Grid item>
        <Button
          id={'btn-kpmgfind-0' + props.index}
          className={classes.findBtn}
          variant="contained"
          size="large"
          color="primary"
          fullWidth={true}
          onClick={() => {
            performSearch(searchValue)
          }}
        >
          <FormattedMessage id="search_button" defaultMessage="KPMG Find" />
        </Button>
      </Grid>
      <Grid item>
        <Button
          id={
            isChina ? 'btn-baidu-0' + props.index : 'btn-google-0' + props.index
          }
          className={classes.askGoogle}
          variant="outlined"
          size="large"
          fullWidth={true}
          disabled={searchValue.trim() ? false : true}
          onClick={() => {
            if (isChina) {
              trackEvents('searchBaidu', {
                searchTerm: searchValue,
                tab: 'intranet',
                enabledFilters: KPMGFindGlobalVariables.getEnabledFilters()
              })
              window.open(`https://www.baidu.com/s?&wd=${searchValue}`)
            } else {
              trackEvents('searchGoogle', {
                searchTerm: searchValue,
                tab: 'intranet',
                enabledFilters: KPMGFindGlobalVariables.getEnabledFilters()
              })
              window.open(`https://www.google.com/search?q=${searchValue}`)
            }
          }}
        >
          {isChina && (
            <FormattedMessage id="ask_baidu" defaultMessage="Ask Baidu" />
          )}
          {!isChina && (
            <FormattedMessage id="ask_google" defaultMessage="Ask Google" />
          )}
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <>
      <div className={classes.center} id={'con-landing-page-01'}>
        <NavBar
          onMenuButtonClick={onMenuButtonClick}
          userName={userName}
          startPage={true}
        />
        <Grid container className={classes.root}>
          {((!searchModalOpen && deviceSettings.renderMobile) ||
            !deviceSettings.renderMobile) && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="flex-start"
            >
              <Grid item>
                <CardMedia
                  image={Logo}
                  className={classes.logo}
                  aria-label={'KPMG Find - Connecting people to insights'}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.title}
                  color="primary"
                >
                  {intl.formatMessage({
                    id: 'title',
                    defaultMessage: 'KPMG Find'
                  })}
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                  className={classes.para}
                >
                  {intl.formatMessage({
                    id: 'tagline',
                    defaultMessage: 'Connecting people to insights'
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Grid
                item
                style={{
                  paddingTop: 32,
                  paddingBottom: 32,
                  position: 'relative'
                }}
              >
                <SearchField
                  navBar={false}
                  dimensions={'start'}
                  onSearchValueChange={(value: any) => {
                    KPMGFindGlobalVariables.setSearchTerm(value)
                    setSearchValue(value)
                  }}
                  searchButtons={<SearchButtons index={2} />}
                  resetSearchValue={searchValue ? false : true}
                  setSearchModalOpen={setSearchModalOpen}
                />
              </Grid>
            </Grid>
            <SearchButtons index={1} />
          </Grid>
        </Grid>
        <div className={classes.slider}>
          <Introduction
            open={showIntroduction}
            onClose={() => {
              userSettings.IntroductionShown = true
              setUserSettings(userSettings)
              setShowIntroduction(false)
            }}
          />
        </div>
        <FeedbackShort
          open={showFeedbackShort}
          onClose={() => {
            setShowFeedbackShort(false)
          }}
          onSend={() => {
            onFeedbackDone(false)
          }}
          onDelay={() => {
            onFeedbackDelay(false)
          }}
        />
        <FeedbackLong
          open={showFeedbackLong}
          onClose={() => {
            setShowFeedbackLong(false)
          }}
          onSend={() => {
            onFeedbackDone(true)
          }}
          onDelay={() => {
            onFeedbackDelay(true)
          }}
        />
      </div>
    </>
  )
}
const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    hasFindConfigurationBeenFetched:
      SettingsStore.selectors.gethasFindConfigurationBeenFetched(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings)),
    storeSearchHistory: (searchQuery: string, userSettings: IUserSetting) =>
      dispatch(SearchStore.actions.storeHistoryItem(searchQuery, userSettings))
  }
}

export default PageStay(connect(mapStateToProps, mapDispatchToProps)(StartPage))
