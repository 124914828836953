import React, { Fragment } from 'react'
import Fuse from 'fuse.js'
import ReactDOMServer from 'react-dom/server'
import { trackException } from './tracking'
import { findAll } from 'highlight-words-core'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

const minWordLength = 3
export const prepareHighlightWords = (
  searchQuery: string | undefined,
  body: string,
  synonymsApplied?: ISynonymsApplied[]
): string[] => {
  //cleanup
  if (searchQuery) searchQuery = searchQuery?.trim()

  //generate query list
  let _searchQuery = [searchQuery ? searchQuery : '']
  if (
    searchQuery &&
    searchQuery.indexOf(' ') !== -1 &&
    searchQuery.length > 4 &&
    !(searchQuery.startsWith('"') && searchQuery.endsWith('"'))
  ) {
    //split searchterm words if not in quotes
    _searchQuery = searchQuery?.split(' ')
  } else {
    //remove quotes if quotes are set
    if (
      searchQuery &&
      searchQuery.startsWith('"') &&
      searchQuery.endsWith('"')
    ) {
      const phraseToCheck = searchQuery.slice(1, searchQuery.length - 1)
      _searchQuery = [phraseToCheck]
    }
  }

  //add fuzzy search terms
  const fuzySearchTerms = addFuzySearchWords(_searchQuery, body)
  fuzySearchTerms.forEach((fuzyTerm: string) => {
    if (!_searchQuery.includes(fuzyTerm)) _searchQuery.push(fuzyTerm)
  })

  //return highlight searchterms
  const result = [..._searchQuery]

  if (synonymsApplied && synonymsApplied.length > 0)
    synonymsApplied.forEach((sym: ISynonymsApplied) => {
      result.push(sym.word)
      if (sym.synonyms && sym.synonyms.length > 0)
        sym.synonyms.forEach((synonyms: string) => {
          result.push(synonyms)
        })
    })

  return result
}

const addFuzySearchWords = (_searchQuery: string[], body: string) => {
  const bodyWords = body.split(' ')
  const fuse = new Fuse(bodyWords, {
    findAllMatches: true,
    threshold: 0.0,
    minMatchCharLength: minWordLength,
    ignoreLocation: true,
    isCaseSensitive: false
  })

  const alrdySearched: string[] = []
  const searchResults: any[] = []
  _searchQuery.forEach((titleWord: any) => {
    if (
      titleWord !== '' &&
      !alrdySearched.find((element: string) => element === titleWord)
    ) {
      alrdySearched.push(titleWord)

      if (titleWord.length > minWordLength) {
        searchResults.push(fuse.search(titleWord))
      } else {
        const lowerCaseTitles = bodyWords.map((titleWord: any) =>
          titleWord.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase()
        )
        const indexOfShortWords = []
        let indexOfShortWord = lowerCaseTitles.indexOf(titleWord.toLowerCase())
        while (indexOfShortWord !== -1) {
          indexOfShortWords.push({
            item: titleWord,
            refIndex: indexOfShortWord,
            secu: true
          })
          indexOfShortWord = lowerCaseTitles.indexOf(
            titleWord.toLowerCase(),
            indexOfShortWord + 1
          )
        }
        searchResults.push(indexOfShortWords)
      }
    }
  })

  const fuzySearchTerms: string[] = []
  searchResults.forEach((fuzyResults: any) => {
    if (fuzyResults)
      fuzyResults.forEach((fuzyResult: any) => {
        if (fuzyResult.item && !fuzySearchTerms.includes(fuzyResult.item))
          fuzySearchTerms.push(fuzyResult.item)
      })
  })
  return fuzySearchTerms
}

const highlightText = (
  body = '',
  searchWords: string | string[] | undefined,
  stringResult = false
): any => {
  try {
    // it can be possible that the first argument "title" is null or undefined
    // this occurs for example on the people result page for the description,
    // so additionally should be checked if the given title parameter is set
    if (!searchWords || !body) {
      return body ? body : ''
    }

    let _searchWords: string[] = []
    if (Array.isArray(searchWords)) {
      _searchWords = searchWords
    } else {
      _searchWords = [searchWords]
    }

    const chunks = findAll({
      searchWords: _searchWords,
      textToHighlight: body,
      autoEscape: true
    })

    const highlightedResult = chunks.map((chunk, index) => {
      const { end, highlight, start } = chunk
      const text = body.substr(start, end - start)

      if (highlight) {
        if (!stringResult) {
          return (
            <Fragment key={index}>
              <b>{text}</b>
            </Fragment>
          )
        } else {
          return ReactDOMServer.renderToStaticMarkup(
            <Fragment key={index}>
              <b>{text}</b>
            </Fragment>
          )
        }
      } else {
        return text
      }
    })

    return !stringResult ? highlightedResult : highlightedResult.join('')
  } catch (error) {
    trackException('Error in highlightText method', error)
    return body
  }
}

export default highlightText
