import { IDataSource } from 'constants/datasourcesConfiguration'
import { IUserSetting } from './userSettings'
import { NavigateFunction, NavigateOptions } from 'react-router-dom'

export const getLanguageLCID = (userSettings: IUserSetting) => {
  const language = userSettings.Language ? userSettings.Language : 'en'
  let lcidCode = '1033'
  switch (language) {
    case 'de':
      lcidCode = '1031'
      break
    case 'ja-JP':
      lcidCode = '1041'
      break
    case 'zh':
      lcidCode = '2052'
      break
    case 'it':
      lcidCode = '1040'
      break
    case 'es':
      lcidCode = '1034'
      break
    case 'fr':
      lcidCode = '1036'
      break
    case 'kr':
      lcidCode = '1042'
      break
    case 'ru':
      lcidCode = '1049'
      break
    default:
      lcidCode = '1033'
      break
  }
  return lcidCode
}

export const updateHistory = (
  navigateFunction: NavigateFunction,
  query: string,
  currentDataSources: IDataSource[],
  currentScope: string
) => {
  //find first active datasource
  const firstActiveDatasource = currentDataSources.find(
    (ds: IDataSource) => ds.enabled
  )
  let firstActiveDatasourceName = firstActiveDatasource
    ? firstActiveDatasource.name.toLowerCase()
    : ''
  if (
    firstActiveDatasourceName === '' ||
    firstActiveDatasourceName === 'intranet'
  ) {
    firstActiveDatasourceName = 'all'
  }
  if (firstActiveDatasourceName === 'kpmg.com') {
    firstActiveDatasourceName = 'aem'
  } else if (firstActiveDatasourceName === 'kpmg mpp') {
    firstActiveDatasourceName = 'kpmg_mpp'
  } else if (firstActiveDatasourceName === 'kpmg.us') {
    firstActiveDatasourceName = 'kpmgus'
  }
  if (currentScope === 'kpmg.com') {
    currentScope = 'aem'
  } else if (currentScope === 'kpmg mpp') {
    currentScope = 'kpmg_mpp'
  } else if (currentScope === 'kpmg.us') {
    currentScope = 'kpmgus'
  }

  const options: NavigateOptions = {
    state: {
      searchFrom: currentScope ? currentScope : 'startpage'
    }
  }

  navigateFunction(
    `/results/${
      currentScope ? currentScope : firstActiveDatasourceName
    }/?${query}`,
    options
  )
}

export const updateHistoryForOffice365 = (
  navigateFunction: NavigateFunction,
  query: string,
  pathComponents: any
) => {
  if (
    pathComponents[0] &&
    pathComponents[0].indexOf('office365/mails') !== -1
  ) {
    // set history to mails
    navigateFunction(`/results/office365/mails/?${query}`)
  } else if (
    pathComponents[0] &&
    pathComponents[0].indexOf('office365/teams') !== -1
  ) {
    // set history to teams
    navigateFunction(`/results/office365/teams/?${query}`)
  } else if (
    pathComponents[0] &&
    pathComponents[0].indexOf('office365/calendar') !== -1
  ) {
    // set history to calendar
    navigateFunction(`/results/office365/calendar/?${query}`)
  } else if (
    pathComponents[0] &&
    pathComponents[0].indexOf('office365/sites') !== -1
  ) {
    // set history to calendar
    navigateFunction(`/results/office365/sites/?${query}`)
  } else {
    // set history to files
    navigateFunction(`/results/office365/?${query}`)
  }
}

export const updateHistoryForKnowledgeExchange = (
  navigateFunction: NavigateFunction,
  query: string,
  pathComponents: any
) => {
  if (
    pathComponents[0] &&
    pathComponents[0].indexOf('knowledgeexchange/catalog') !== -1
  ) {
    // set history to catalog
    navigateFunction(`/results/knowledgeexchange/catalog/?${query}`)
  } else {
    // set history to open
    navigateFunction(`/results/knowledgeexchange/?${query}`)
  }
}

export const updateHistoryForAlex = (
  navigateFunction: NavigateFunction,
  query: string,
  pathComponents: any
) => {
  if (pathComponents[0] && pathComponents[0].indexOf('alex/topics') !== -1) {
    // set history to topics
    navigateFunction(`/results/alex/topics/?${query}`)
  } else if (
    pathComponents[0] &&
    pathComponents[0].indexOf('alex/manuals') !== -1
  ) {
    // set history to manuals
    navigateFunction(`/results/alex/manuals/?${query}`)
  } else if (
    pathComponents[0] &&
    pathComponents[0].indexOf('alex/search') !== -1
  ) {
    // set history to search
    navigateFunction(`/results/alex/search/?${query}`)
  } else {
    // set history to featured
    navigateFunction(`/results/alex/?${query}`)
  }
}

export const updateHistoryForServiceNowAU = (
  navigateFunction: NavigateFunction,
  query: string,
  pathComponents: any
) => {
  if (
    pathComponents[0] &&
    pathComponents[0].indexOf('servicenowau/knowledge') !== -1
  ) {
    // set history to knowledge
    navigateFunction(`/results/servicenowau/knowledge/?${query}`)
  } else if (
    pathComponents[0] &&
    pathComponents[0].indexOf('servicenowau/service') !== -1
  ) {
    // set history to service
    navigateFunction(`/results/servicenowau/service/?${query}`)
  } else {
    // set history to all
    navigateFunction(`/results/servicenowau/?${query}`)
  }
}
